import {post} from "../networking/Server";
import {MStore} from "../networking/stores/MainStore";

export function cotrolCounts() {
  post("api/user/control-counts",{alert:false}).then((res) => {

    if(res.result)
      MStore.setCounts({...MStore.counts,"out-of-users":res["out-of-users"]})
  });
}
