import Axios from "./Axios";
import jwt_decode from "jwt-decode";
import { error, success } from "../functions/toast";
import string from "../lngProvider/locales/tr_TR.json";
import { Store } from "./stores/Store";
import { toParse } from "../functions/json";
let task;

export function get(
  address,
  params = {},
  {
    alert = true,
    loading = true,
    onFinally = () => {},
    success: _success = () => {},
  }
) {
  Axios.defaults.headers.common["x-access-token"] =
    "Bearer " + localStorage.getItem("token");

  address = "admin/" + address;

  return new Promise((resolve, reject) => {
    if (loading) Store.setLoading(true);

    let language = localStorage.getItem("language");
    if (language) language = toParse(language);
    else language = { locale: "tr" };

    const lang = language.locale == "tr" ? "tr" : "en";

    Axios.get(address, { params: { lang, ...params } })
      .then(({ data }) => {
        if (data.result || data.error == false) {
          _success(data);
          resolve(data);
        } else {
          if (alert)
            error(data.errorCode ? string[data.errorCode] : "Bir Sorun Oluştu");
          reject(data);
        }
      })
      .catch((err) => {
        if (alert)
          error("Bağlantı Hatası", "Lütfen daha sonra tekrar deneyiniz.");
        reject(err);
      })
      .finally(() => {
        onFinally();
        if (loading) Store.setLoading(false);
      });
  });
}

export async function post(
  address,
  params = null,
  {
    file = false,
    loading = true,
    alert = true,
    onFinish = () => {},
    success: _success,
  } = {}
) {
  Axios.defaults.headers.common["x-access-token"] =
    "Bearer " + localStorage.getItem("token");

  address = "admin/" + address;

  return new Promise(function (resolve, reject) {
    if (loading) Store.setLoading(true);

    let language = localStorage.getItem("language");
    if (language) language = toParse(language);
    else language = { locale: "tr" };

    const lang = language.locale == "tr" ? "tr" : "en";

    if (params) {
      params = { ...params, lang };
    } else {
      params = { lang };
    }

    setTimeout(function () {
      task = Axios.post(address, params);
      task
        .then(({ data }) => {
          onFinish(false);

          if (loading) Store.setLoading(false);
          try {
            data = JSON.parse(data);
          } catch {}

          if (data.result) {
            if (_success) success(_success);
            resolve(data);
          } else {
            if (alert) {
              if (data.errorCode) error(string[data.errorCode]);
              else error();
            }

            reject(data);
          }
        })
        .catch((err) => {
          onFinish(false);
          if (loading) Store.setLoading(false);
          if (alert)
            error("Bağlantı Hatası", "Lütfen Daha Sonra Tekrar Deneyiniz");
          reject("No_Connect" + " " + err);
        });
    }, 1000);
  });
}

export function getTask() {
  return task;
}

export async function cancelPost(task) {
  task.cancel((res) => {
    console.warn("İstek İptal Edildi");
  });
}

export function getURL() {
  return "https://cyberistanbul.com:5000/";
}

export function getUser(token) {
  try {
    if (token) {
      return jwt_decode(token);
    } else {
      return jwt_decode(localStorage.getItem("token"));
    }
  } catch (e) {}
  return {};
}
