import React from "react";
import { Spin } from "antd";

const SearchBox = ({
  styleName,
  placeholder,
  onChange,
  value,
  loading = false,
}) => {
  return (
    <div className={`gx-search-bar ${styleName}`}>
      <div className="gx-form-group">
        <input
          className="ant-input"
          type="search"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        <span className="gx-search-icon gx-pointer">
          <i className="icon icon-search" />
        </span>
        {loading ? (
          <span
            style={{ left: "auto", right: 0 }}
            className="gx-search-icon  gx-pointer"
          >
            <Spin />
          </span>
        ) : null}
      </div>
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: "",
  value: "",
};
