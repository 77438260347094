import React from "react";
import {getUser} from "../networking/Server";
import {l_moment} from "../functions/l_moment";

export const VERSION = "1.0.0";
export const API_VERSION = "1.0.0";

const pages = [
  {
    header: "yonetim",
  },
  {
    name: "Anasayfa",
    path: "home",
    icon: "dasbhoard",
    nav: true,
    page: import("../routes/Home"),
  },
  {
    admin:true,
    name: "adminler",
    path: "admins",
    icon: "user",
    nav: true,
    page: import("../routes/Admins"),
  },
  {
    name: "kullanicilar",
    path: "users",
    icon: "profile",
    nav: true,
    page: import("../routes/Users"),
  },
  {
    name: "paketibitecekolanlar",
    path: "out-of-users",
    icon: "profile",
    nav: true,
    page: import("../routes/Users/outofpackets"),
  },
  {
    name: "yenihesapekle",
    path: "create-account",
    icon: "add-circle",
    nav: true,
    page: import("../routes/Users/create"),
  },
  {
    name: "topluurunekle",
    path: "create-products",
    icon: "add-circle",
    nav: true,
    page: import("../routes/AddBulkProduct/index"),
  }
];

export function getPages(nav) {
  let admin = getUser()
  if(admin)
    admin = admin.admin
  else
    admin = false

  let _pages = pages.filter((e) => (nav ? (e.nav == nav || e.header) : true) && admin?true:(!e.admin));

  return _pages;
}


export const Authorities = [
  {id:1, value:"Ürün Yönetim"},
  {id:2, value:"Stok Yönetim"},
  {id:3, value:"Personel Yönetim"},
  {id:4, value:"İstatistik Yönetim"},
  {id:5, value:"İşletme Ayar Yönetim"},
  {id:6, value:"Masa Yönetim"},
  {id:7, value:"Sipariş Yönetim"},
  {id:8, value:"Rezervasyon Yönetim"},
  {id:9, value:"Gel Al Yönetim"},
  {id:10, value:"Adrese Sipariş Yönetim"},
  {id:11, value:"Anket Yönetim"},
  {id:12, value:"Reklam Yönetim"},
  {id:13, value:"Qr Uygulama Yönetim"},
  {id:14, value:"QR Web Yönetim"},
  {id:15, value:"Dijital Menü Yönetim"},
  {id:16, value:"Anket Yönetim"},
  {id:17, value:"Qr Menü Web Sipariş"},
  {id:18, value:"Araç Yönetimi"},
  {id:19, value:"Bildirimler"},
];

export const PacketTypes = {
  Pos: {
    id: 1,
    value: "Pos",
    keys:[1,2,3,5,6,7,19]
  },
  QrMenu: {
    id: 2,
    value: "Qr Menü",
    keys:[1,14]
  },
  QrSurvey: {
    id: 3,
    value: "Qr Anket",
    keys:[11,16]
  },
  QrOrder: {
    id: 4,
    value: "Qr Sipariş",
    keys:[1,2,4,6,7,14,17]
  },
  QrUygulama: {
    id: 5,
    value: "Qr Uygulama",
    keys:[1,2,4,5,6,7,9,10,11,13,14]
  },
  CustomerFeedback: {
    id: 6,
    value: "Müşteri Memnuniyet Sistemi",
    keys:[11,16]
  },
  DigitalMenu: {
    id: 7,
    value: "Dijital Menü",
    keys:[1,15]
  },
  Vale: {
    id: 8,
    value: "Vale Hizmeti",
    keys:[3,18,19]
  }
};

export const StaffTypes = {
  Kasiyer: { id: 1, value: "Kasiyer" },
  Garson: { id: 2, value: "Garson" },
  Mutfak: { id: 3, value: "Mutfak" },
  Menu: { id: 4, value: "Menu" },
  LuksMenu: { id: 5, value: "Luks Menu" },
  Market: { id: 6, value: "Market" },
  Kurye: { id: 7, value: "Kurye" },
  Vale: { id: 8, value: "Vale" },
};


export const PacketTimes = {
  BirGun: { id: 1, value: "1 Gün",data:l_moment().add(1,"days").format("YYYY-MM-DD HH:mm:00") },
  UcGun: { id: 2, value: "3 Gün",data:l_moment().add(3,"days").format("YYYY-MM-DD HH:mm:00") },
  BirHafta: { id: 3, value: "1 Hafta",data:l_moment().add(1,"weeks").format("YYYY-MM-DD HH:mm:00") },
  IkiHafta: { id: 4, value: "2 Hafta",data:l_moment().add(2,"weeks").format("YYYY-MM-DD HH:mm:00") },
  BirAy: { id: 5, value: "1 Ay",data:l_moment().add(1,"months").format("YYYY-MM-DD HH:mm:00") },
  UcAy: { id: 6, value: "3 Ay",data:l_moment().add(3,"months").format("YYYY-MM-DD HH:mm:00") },
  BirYil: { id: 7, value: "1 Yıl",data:l_moment().add(1,"years").format("YYYY-MM-DD HH:mm:00") },
  Suresiz: { id: 8, value: "Süresiz",data:l_moment().add(100,"years").format("YYYY-MM-DD HH:mm:00") },
};

export function findType(data, id, key = "value") {
  let res = " ";
  try {
    res = Object.values(data).find((e) => e.id == id);
    if (key) res = res[key];
  } catch (e) {}
  return res;
}
