import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import { observer } from "mobx-react";
import { Store } from "../../networking/stores/Store";
import { MStore } from "../../networking/stores/MainStore";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";

const UserProfile = ({ pathname }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li className="gx-text-danger" onClick={() => dispatch(userSignOut())}>
        <IntlMessages id="cikisyap" />
      </li>
    </ul>
  );

  const user = MStore.user;

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        onVisibleChange={setVisible}
        visible={visible}
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Avatar
            src={require("../../assets/images/logo2.png")}
            style={{background:'white',borderRadius:1000, width: 45, height: 45 }}
            className="gx-pointer gx-mr-3"
            alt=""
          />
          <span className="gx-avatar-name">
            {user?.username}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        </div>
      </Popover>
    </div>
  );
};

export default observer(UserProfile);
