import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from 'util/Api'
import {Store} from "../../networking/stores/Store";
import {MStore} from "../../networking/stores/MainStore";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({email, password, name}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        axios.defaults.headers.common['authorization'] = "Bearer " + data.token.access_token;
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        //console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      //console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = (token,user) => {
  return (dispatch) => {
    localStorage.setItem("token",token)
    MStore.setUser(user)
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    dispatch({type: USER_TOKEN_SET, payload: token});


  }
};



export const userSignOut = () => {

  return (dispatch) => {
    dispatch({type: FETCH_START});

    localStorage.removeItem("token");
    dispatch({type: FETCH_SUCCESS});
    dispatch({type: SIGNOUT_USER_SUCCESS});
  }
};
