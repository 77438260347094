import React from "react";
import { Link, Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import { getUser } from "../networking/Server";
import { getPages } from "../constants/Config";
const App = ({ match }) => {
  let authorities = [];
  try {
    authorities = getUser()["authorities"];
    if (!Array.isArray(authorities)) authorities = [];
  } catch (e) {}
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        {getPages().map(({ path, page, pages }) => {
          if (Array.isArray(pages)) {
            return pages.map(({ path, page }) => (
              <Route
                path={match.url + path}
                component={asyncComponent(() => page)}
              />
            ));
          }

          return (
            <Route
              path={match.url + path}
              component={asyncComponent(() => page)}
            />
          );
        })}
        <Route path={""} component={asyncComponent(() => import("./404"))} />
      </Switch>
    </div>
  );
};
export default App;
