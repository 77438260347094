import antdBg from "antd/lib/locale-provider/bg_BG"
import enMessages from "../locales/bg_BG.json";

const BgLang = {
  messages: {
    ...enMessages
  },
  antd: antdBg,
  locale: 'en-BG',
};
export default BgLang;
