import React, { useState } from "react";
import { Spin } from "antd";
import { Store } from "../networking/stores/Store";
import { observer } from "mobx-react-lite";

const FullPageLoading = () => {
  if (!Store.loading) return null;
  return (
    <div
      style={{
        zIndex: 99999,
        position: "absolute",
        flex: 1,
        height: "100%",
        width: "100%",
      }}
      className="gx-flex-row gx-justify-content-center gx-align-items-center"
    >
      <Spin />
    </div>
  );
};

export default observer(FullPageLoading);
