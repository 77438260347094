import React, { useEffect, useState } from "react";
import { Avatar, Button, Layout, Popover, Select } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions";
import { FullscreenOutlined, CameraFilled } from "@ant-design/icons";
import { Store } from "../../networking/stores/Store";
import IntlMessages from "../../util/IntlMessages";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { MStore } from "../../networking/stores/MainStore";
import { observer } from "mobx-react-lite";

const { Header } = Layout;

const Topbar = ({ webview = false, history }) => {
  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const dispatch = useDispatch();


  useEffect(() => {}, []);

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => {
              dispatch(switchLanguage(language));
              MStore.setLanguage(language);
              localStorage.setItem("language", JSON.stringify(language));
            }}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );


  let elem = document.documentElement;
  function openFullScreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }
  const user = MStore.user;
  return (
    <Header>
      {!webview &&
      (navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE)) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}

      {width >= TAB_SIZE ? (
        <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img alt="" width={25} src={require("assets/images/logo2.png")} />
            <div style={{ margin: 15 }}>Pos Admin</div>
          </div>
        </Link>
      ) : null}

      <ul className="gx-header-notifications gx-ml-auto">
        {!webview ? (
          <li className="gx-language">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={languageMenu()}
              trigger="click"
            >
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <i className={`flag flag-24 flag-${MStore.language.icon}`} />
                <span className="gx-pl-2 gx-language-name">
                  {MStore.language.name}
                </span>
                <i className="icon icon-chevron-down gx-pl-2" />
              </span>
            </Popover>
          </li>
        ) : null}

        {!webview && width >= TAB_SIZE ? (
          <li style={{ marginTop: 10 }}>
            <Button
              type="primary"
              icon={<FullscreenOutlined />}
              onClick={openFullScreen}
            />
          </li>
        ) : null}
      </ul>
    </Header>
  );
};

export default observer(Topbar);
