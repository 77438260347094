import React from "react";
import AppNotification from "../../components/AppNotification";
import { Popover } from "antd";
import MailNotification from "../../components/MailNotification";

const AppsNavigation = () => (
  <ul
    className="gx-app-nav"
    style={{
      height: 20,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {/*
      <li className="gx-notify">
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={<AppNotification />}
        trigger="click"
      >
        <span className="gx-pointer gx-d-block">
          <i className="icon icon-notification" />
        </span>
      </Popover>
    </li>

    <li className="gx-msg">
      <Popover
        overlayClassName="gx-popover-horizantal"
        placement="bottomRight"
        content={<MailNotification />}
        trigger="click"
      >
        <span className="gx-pointer gx-status-pos gx-d-block">
          <i className="icon icon-chat-new" />
          <span className="gx-status gx-status-rtl gx-small gx-orange" />
        </span>
      </Popover>
    </li>
      */}
  </ul>
);

export default AppsNavigation;
